/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

 module.exports = {
  logo: 'iunxi.svg',
  logoalt: 'iunxi-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_iunxi.scss',
  name: 'Iunxi',
  clientName: 'iunxi',
  phone: '+31 88 540 0500',
  email: 'info@iunxi.com',
  website: 'https://www.iunxi.com/nl/en/',
  glossary: true,
  // langFileName: 'nlc.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#0275b5',
      color2: '#4cb5f0',
      color3: '#083f6f',
      color4: '#307ec1',
    },
  },
  language: 'en',
  showAnimations: true,
  showAssetDescription: true,
};
